.about-to-buy-post-image {
  border-radius: 5px;
  height: 100px;
  margin-bottom: 16px;
  margin-top: 8px;
  object-fit: cover;
  width: 100%;
}

.badge {
  padding: 8px !important;
}

.bewb {
  margin-top: 2px;
  height: unset !important;
  width: 33px;
}

.bewb-small {
  margin-right: 9px;
  margin-top: -7px;
  width: 18px;
}

.bewb-large {
  margin-bottom: 5px;
  width: 99px
}

.bi {
  color: #F9F9F9 !important;
  font-size: 20px;
}

.faqIcon {
  color: gray !important;
  font-size: 16px;
  margin-left: 4px;
}

.blur-me {
  background-color: black !important;
  opacity: 0;
}

.btn-dark {
  background-color: transparent;
}

.bubble-shadow {
  box-shadow: 1px 1px darkgrey;
}

.bubble-wrap {
  margin-right: 12px;
  width: 76px;
}

.camlyfe-container {
  margin: auto;
  max-width: 512px;
}

.camlyfe-header {
  color: #F9F7F5;
  font-size: 20px;
  font-weight: 500;
}

.caption-input {
  min-height: 100px;
}

.caption-input input {
  background: black;
  border: none;
}

.category-height {
  height: 20vh;
}

.category-height img {
  object-fit: cover !important;
}

.clean-error {
  color: red !important;
  font-size: 11px;
  opacity: 0.6;
}

.contact-lynk {
  margin-left: 2px;
  padding: 15px;
}

.wallet-lynk {
  margin-left: 16px;
  margin-top: 18px;
}

.wallet-lynk a {
  color: #fc49c4 !important;
  font-weight: 700;
  text-decoration: none !important;
}

.contact-lynk a {
  color: #fc49c4 !important;
  font-weight: 700;
  text-decoration: none !important;
}

.contact-us p {
  color: pink;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.create-post-image {
  object-fit: contain !important;
  margin-top: 6vh;
  max-height: 50vh;
  max-width: 100%;
  width: unset;
}

.darkMode {
  background-color: black;
  min-height: 100vh;
  @media screen and (max-width: 600px) {
    min-height: 40vh;
  }
}

.edit-profile-pic {
  border-radius: 11px;
  border: 2px solid black;
  background-color: pink;
  color: black;
  height: 22px;
  line-height: 1.2;
  padding-left:6px;
  padding-top: 0px;
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 22px;
}

.extraLight {
  font-weight: 100;
}

.footer {
  background-color: black;
  border-top: 1px solid gray;
  padding-top: 13px;
  height: 60px;
}

.flex {
  margin-top: 21px;
}

.flex div {
  flex: 1;
}

.flex h6 {
  color: #F9F7F5 !important;
  text-align: center;
}

.footer .col {
  padding: 0;
}

.grid {
  margin-bottom: 60px;
}

.grid .col-4 {
  padding: 0;
}

.blackinput .form-control:focus {
  background-color: black;
  box-shadow: unset;
  color: white;
}

.hangman {
  filter: blur(8px);
}

.header {
  font-size: 46px;
  font-weight: 300;
  padding-top: 15vh;
}

h6 {
  margin-bottom: 0;
}

.message {
  font-size: 30px !important;
}

.message span {
  font-size: 60px !important; 
  font-weight: 100;
}

.message-user-title {
  margin-right: 15px;
  margin-top: 19px;
}

img {
  background-color: black;
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.image-loading {
  height: 100%;
  width: 100%;
}

.image-loading-grid {
  height: 100%;
}

.image-loading .spinner-grow {
  left: 47%;
  position: absolute;
  top: 43%;
}

.label-overflow {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.login-button {
  font-size: 12px;
  margin-left: 5px;
  margin-top: -5px;
}

.login-button i {
  font-size: 12px;
  margin-left: 4px;
}

.logout-profile-button {
  font-size: 13px;
}

.logout-profile-button i {
  font-size: 12px;
  margin-left: 4px;
}

.menu-title-home {
  color: white !important;
  text-decoration: none !important;
  font-weight: 500;
}

.menus-mb {
  background-color: #2d2d2d !important;
}

.message-row {
  border-top: 1px solid gray;
  padding-bottom: 14px;
  padding-top: 14px;
}
.no-photo {
  color: ghostwhite;
  font-size: 100px !important;
  position: fixed;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-photo1 {
  color: black;
  text-align: center;
}


.no-users-yet {
  min-height: 105px;
}

.open-nav .btn-close {
  background-color: white !important;
}

.overflow-vert {
  height: calc(100vh - 100px);
  overflow-y: scroll !important;
}

p {
  font-weight: 600;
  margin-bottom: 0;
}

.pick-username-text {
  font-size: 20px !important;
  animation: fadeIn2 1s infinite;
}

.pink-progress .progress-bar {
  background-color: #fc49c4 !important;
}

.post-video {
  max-height: 100%;
  max-width: 100vw;
}

.pulse {
  animation: fadeIn 1.88s infinite;
}

.price-on-post {
  color: white;
  font-size: 28px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.price-on-post p {
  font-size: 12px !important;
  margin-top: 10px;
  line-height: 21px;
}

.price-on-post small {
  color: #fc49c4;
  font-weight: 400 !important;
  font-size: 30px !important;
}

.price-on-post span {
  color: #fc49c4;
  font-size: 40px !important;
}

.private-thumbnail {
  border-radius: 5px;
  height: 122px;
  object-fit: cover;
}

.profile-video-thumbnail {
  height: 100%;
  object-fit: cover !important;
  width: 100%;
}

.pick-username {
  padding-top: 15vh;
}

.profile-description {
  line-height: 1.3;
}

.profile-grid-photo {
  height: 33vw;
  max-height: 210px;
}

.profile-grid-photo img {
  object-fit: cover !important;
}

.post-description {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 8px;
  margin-top: 4px;
}

.post-image {
  background-color: gray;
  height: 375px;
  max-height: 375px;
  min-height: 300px;
  overflow: hidden;
  padding: 0;
}

.post-likes {
  font-size: 12px;
  font-weight: 700;
}

.post-row {
  border-top: 1px solid gray;
  height: 50px;
  padding-top: 14px;
}

.post-row-lg {
  border-top: 1px solid gray;
  height: 85px;
  padding-top: 14px;
}

.post-subtitle {
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
}

.post-title {
  font-size: 14px;
  font-weight: 700;
}

.post-title-wrapper {
  margin-top: 5px;
}

.price-input {
  position: absolute;
  right: 0;
  width: 100px;
}

.priceDesc {
  font-size: 12px;
  color: grey;
}

.right {
  position: absolute;
  right: 8px;
}

.shit-dick {
  display: block;
  font-weight: 300;
  word-wrap: break-word;
  width: 80vw;
  white-space: unset;
  text-align: left;
  max-width: 500px;
}

.shit-dick1 {
  display: block;
  font-weight: 300;
  word-wrap: break-word;
  width: 80vw;
  white-space: unset;
  margin-left: 13vw;
  max-width: 500px;
  text-align: left;
}

.show-tags-on-post-image {
  position: absolute;
  right: 10%;
  top: 50%;
}

.slideTranny {
  animation: slideLeft 2s;
}

small {
  font-weight: 600;
}

.space-thirds {
  min-width: 40vw;
}

.speaker {
  bottom: 5px;
  color: #4fe4ff !important;
  font-size: 40px;
  position: absolute;
  right: 10px;
}

textarea, input {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.validate-age-modal {
  font-size: 12px;
}

.xs {
  color: #fc49c4;
  font-size: 12px;
  font-weight: 700;
  margin-top: 14px;
  text-align: end;
}

/* -------------- UTILS ------------------ */

.black-background {
  background-color: #2d2d2d;
}

.bold {
  font-weight: 700 !important;
}

.bottom-absolute {
  bottom: 0px;
  padding-bottom: 18px !important;
  padding-top: 18px !important;
  position: absolute;
  left: 0;
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.fixed-center {
  position: fixed;
  top: 50vh;
  width: 97%;
}

.mid {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-middle {
  height: 50vh;
  width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input:checked {
  /* background-color: pink !important;
  border-color: #fc49c4 !important; */
}

.full-height {
  background-color: black;
  height: calc(100vh - 60px);
}

.full-height-percent {
  height: 100% !important;
}

.full-height-percent .middle {
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-height .middle {
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media screen and (max-width: 389px) {
  .full-height1 .middle {
    width: 100%;
    position: fixed;
    bottom: 17vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 390px) {
  .full-height1 .middle {
    width: 100%;
    position: fixed;
    bottom: 17vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.full-width {
  width: 100%;
}

.genderness {
  color: white;
}

.genderness h6 {
  font-size: 13px;
  text-align: end;
}

.genderness input {
  height: 22px;
  margin-top: 5px;
  width: 40px !important;
}

.green {
  color: #39FF14 !important;
}

.greenDark {
  color: green !important;
}

.half-width {
  width: 50%
}

.huge-margin-top {
  padding-top: 20vh;
}

.list-group-a {
  color: white !important;
}

.ml-2 {
  margin-left: 8px;
}

.mt-xl {
  margin-top: 30vh;
}

.mr-3 {
   margin-right: 16px;
}

.opaque, 
.opaque input {
  opacity: 0.4;
}

.overlay-dark {
  background-color: #2d2d2d;
  color: whitesmoke;
  font-weight: 300;
}

.overlay-dark .list-group-item {
  background-color: #2d2d2d !important;
  color: whitesmoke;
}

.overlay-price {
  color: white;
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
}

.pinkB {
  background-color: #fc49c4 !important;
}

.pink {
  border-color: #fc49c4 !important;
  color: #D4D4D4 !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 16px;
}

.pr-0 {
  padding-right: 0px !important;
}

.red-btn {
  border-color: red !important;
  color: white !important;
}

.sidenav-small {
  color: #fc49c4;
  font-size: 16px !important;
  font-weight: 400;
  margin-left: 8px;
}

.sidenav-text {
  font-size: 12px;
}

.text-left {
  text-align: start;
}

.text-right {
  text-align: end;
}

.unset-width {
  width: unset !important;
}

/* ------------- COLORS ----------------- */

.red {
  background-color: red;
}

.text-blue {
  color: #07bfff !important;
}

.text-grey {
  color: grey;
}

.text-pink-light {
  color: #fc49c4 !important;
  font-weight: 100;
}

.text-pink {
  color: #fc49c4 !important;
}

.text-red {
  color: red !important;
}

.text-white {
  color: #F9F7F5 !important;
}

.tiny-grid {
  height: 30px;
  object-fit: cover;
  width: 30px;
}

.userExplorePagePic {
  max-height: 13vw;
  object-fit: cover;
  width: 100%;
}

/*------------- TRANNIES -------------------*/

@keyframes fadeIn {
  0% { opacity: 1; }
  50% { opacity: 0.6;}
  100% { opacity: 1s; }
}

@keyframes fadeIn2 {
  0% { opacity: 1; }
  50% { opacity: 0.3;}
  100% { opacity: 1s; }
}

@keyframes slideLeft {
  0% { left: 0px; }
  100% { left: 2200px; }
}